/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes, Outlet} from 'react-router-dom'
import {useEffect} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Offers} from './offersPage'
import {OfferDetail} from './OfferDetail'

const ErrorsLayout = () => {
  useEffect(() => {
    // document.body.style.backgroundImage = `none`
    // return () => {
    //   document.body.style.backgroundImage = `url(${toAbsoluteUrl('/media/patterns/header-bg.jpg')})`
    // }
  }, [])

  return <Outlet />
}

const OfferRoute = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='/' element={<Offers />} />
      <Route path='detail' element={<OfferDetail />} />

      <Route index element={<Offers />} />
    </Route>
  </Routes>
)

export {OfferRoute}
