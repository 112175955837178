/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
    className: string, title: string, series: Array<number>, labels: Array<string>
}

const ChartsWidgetGeneral: React.FC<Props> = ({ className, title, series, labels }) => {
   
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()
    const refreshMode = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, series, labels, title))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshMode()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode])

    return (
        <div className={`card card-bordered ${className}`}>
            {/* begin::Body */}
            <div className='card-body' style={{ padding: '0.5rem 0.5rem' }}>
                {/* begin::Chart */}
                <div ref={chartRef} id={`kt_charts_widget_3_chart_${className}`} ></div>
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { ChartsWidgetGeneral }

function getChartOptions(height: number, series: Array<number>, labels: Array<string>, title: string): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')
    const baseColor = getCSSVariableValue('--kt-info')
    const lightColor = getCSSVariableValue('--kt-info-light')

    return {
        series: series,
        dataLabels: { enabled: true, formatter: function (val, opt) { return opt.w.config.series[opt.seriesIndex] + " (" + parseFloat(val.toString()).toFixed(0) + "%)" } },
        chart: {
            width: '100%',
            type: 'pie',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true || '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value'
                    },
                    svg: {
                        filename: undefined,
                    },
                    png: {
                        filename: undefined,
                    }
                },
                autoSelected: 'zoom'
            },
        },
        labels: labels,
        legend: {
            position: "bottom", // Etiketlerin pozisyonunu alt k�sma ayarlar.
            horizontalAlign: "center" // Etiketleri yatay olarak hizalar.
        },
        colors: ["#1E90FF", "#8B008B", "#FF1493", "#FF4500", "#FF8C00", "#FFD700", "#32CD32", "#008000", "#4169E1", "#483D8B"],
        title: {
            text: title,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238'
            },
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -20,
                },
            }
        }
    }
}