import React, {FC, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import qs from 'qs'
import {listPublicTermDetail} from '../../pages/offer/list/core/_requests'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {createOfferNote} from '../../pages/offer/list/core/_requests'
import Swal from 'sweetalert2'
import {getOfferByOfferId} from '../../pages/offer/list/core/_requests'
const OfferDetail: FC = () => {
  const [data, setData] = useState<any>([])
  const [message, setMessage] = useState<any>([])
  const [termData, setTermData] = useState<any>([])
  const [isAnyFeedback, setIsAnyFeedback] = useState<any>(false)
  const intl = useIntl()

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).offerId
    getOfferByOfferId(qsd).then((res: any) => {
      console.log(res)
      if (res.length > 0) {
        setIsAnyFeedback(true)
      }
    })
  }, [])
  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).offerId
    listPublicTermDetail(qsd).then((res: any) => {
      console.log(res)
      setTermData(res)
      const groupedData: any = {}
      res[0].offerItems.map((item: any) => {
        let name = item.eventService.service?.parentService?.name
        if (!groupedData[name]) {
          groupedData[name] = {name, offersVMs: [item]}
        } else {
          groupedData[name].offersVMs.push(item)
        }
        const groupedArray = Object.values(groupedData)
        setData(groupedArray)
      })
    })
  }, [])
  console.log(data)

  const sendMessage = () => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).offerId
    let payload = {
      offerId: qsd,
      name: message,
    }

    createOfferNote(payload).then((res: any) => {
      Swal.fire({
        icon: 'success',
        title: `<strong>${intl.formatMessage({
          id: 'Geri Bildirim Başarı İle Gönderildi',
        })}...</strong>`,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      setMessage('')
      setIsAnyFeedback(true)
    })
  }
  return (
    <div className=' d-flex row mt-16 mx-0  justify-content-center align-items-center'>
      <div className='card col-10 '>
        <div className='card-body p-lg-20'>
          <div className='d-flex flex-column flex-xl-row'>
            <div className='flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0'>
              <div className='mt-n1'>
                <div className='m-0'>
                  <div className='fw-bold fs-3 text-gray-800 mb-8'>{termData[0]?.offerName}</div>

                  <div className='row g-5 mb-11'>
                    <div className='col-sm-4'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Başlangıç Tarihi:</div>

                      <div className='fw-bold fs-6 text-gray-800'>{termData[0]?.termStartTime}</div>
                    </div>

                    <div className='col-sm-4'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Bitiş Tarihi:</div>

                      <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap'>
                        <span className='pe-2'>{termData[0]?.termEndTime}</span>
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Bölge:</div>

                      <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap'>
                        <span className='pe-2'>{termData[0]?.region}</span>
                      </div>
                    </div>
                  </div>

                  <div className='row g-5 mb-11'>
                    <div className='col-sm-4'>
                      {/* <div className='fw-semibold fs-7 text-gray-600 mb-1'>Teklif Tarihi:</div>

                        <div className='fw-bold fs-6 text-gray-800'>{offerDate}</div> */}
                      <div className='fw-semibold fs-7 text-gray-600 mb-1'>Kapasite:</div>
                      <div className='fw-bold fs-6 text-gray-800'>{termData[0]?.capacity}</div>
                    </div>

                    <div className='col-sm-4'>
                      {/* <div className='fw-semibold fs-7 text-gray-600 mb-1'>
                          Geçerlilik Tarihi:
                        </div> */}

                      <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap'>
                        {/* <span className='pe-2'>{validDate}</span> */}
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap'>
                        {/* <span className='pe-2'>{capacity}</span> */}
                      </div>
                    </div>
                  </div>

                  {data.map((dt: any) => {
                    return (
                      <div className='flex-grow-1 ' style={{marginTop: '100px'}}>
                        <div className='fw-bold fs-3 text-gray-800 mb-8'>{dt.name && dt.name}</div>

                        <div className='table-responsive border-bottom mb-9'>
                          <table className='table mb-3'>
                            <thead>
                              <tr className='border-bottom fs-6 fw-bold text-muted'>
                                <th className='min-w-175px pb-2'>
                                  {intl.formatMessage({id: 'OFFER.SERVICE/DAY'})}
                                </th>
                                <th className='min-w-70px pb-2'>
                                  {intl.formatMessage({id: 'Supplier'})}
                                </th>
                                <th className='min-w-70px text-end pb-2'>
                                  {' '}
                                  {intl.formatMessage({id: 'OFFER.UNIT1'})}
                                </th>
                                <th className='min-w-80px text-end pb-2'>
                                  {intl.formatMessage({id: 'OFFER.UNIT2'})}
                                </th>
                                <th className='min-w-100px text-end pb-2'>
                                  {intl.formatMessage({id: 'OFFER.PRICE'})}
                                </th>
                              </tr>
                            </thead>
                            <tbody className=''>
                              {(dt as any).offersVMs?.map((a: any, key: any) => {
                                // const color = colors[colorIndex]
                                // colorIndex = (colorIndex + 1) % colors.length
                                return (
                                  <>
                                    <tr className='fw-bold text-gray-700 fs-6 text-end  '>
                                      <td className='d-flex align-items-center text-center'>
                                        <div
                                          // key={a.eventServiceId}
                                          className='form-check form-check-sm form-check-custom form-check-solid me-3'
                                        >
                                          <input
                                            className='form-check-input '
                                            style={{
                                              // border: `2px solid ${color}`,
                                              borderRadius: '50px',
                                            }}
                                            type='checkbox'
                                            // id={a.eventServiceId}
                                            // data-kt-check={a.eventServiceId}
                                            checked={true}
                                            onFocus={(e) => {
                                              e.target.style.border = '2px solid #009ef7'
                                            }}
                                          />
                                        </div>
                                        <div className='fw-semibold ms-5'>
                                          <div className='fs-7 mb-1'>
                                            {a.eventService.day.name == 'Etkinlik Boyunca'
                                              ? a?.day?.name
                                              : a?.eventService.day.theDate?.slice(0, 10)}
                                          </div>

                                          <span className='fs-5 fw-bold text-dark mb-2'>
                                            {a?.serviceName && a.serviceName}
                                          </span>

                                          <div
                                            style={{
                                              display: 'flex  !important',
                                              flexDirection: 'row',
                                              width: '100%',
                                            }}
                                          >
                                            {a?.serviceSpesification?.map(
                                              (item: any) => item + ','
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          display: 'tableCell',
                                          verticalAlign: 'middle',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {a?.eventService?.applicationUser?.name}
                                      </td>
                                      <td
                                        style={{
                                          display: 'tableCell',
                                          verticalAlign: 'middle',
                                          textAlign: 'end',
                                        }}
                                      >
                                        {a?.eventService.unit1 && a.eventService.unit1.name}(
                                        {a?.eventService.unit1Quantity &&
                                          a.eventService.unit1Quantity}
                                        )
                                      </td>
                                      <td
                                        style={{
                                          display: 'tableCell',
                                          verticalAlign: 'middle',
                                          textAlign: 'end',
                                        }}
                                      >
                                        {a?.eventService.unit2 && a.eventService.unit2.name}(
                                        {a?.eventService.unit2Quantity &&
                                          a.eventService.unit2Quantity}
                                        )
                                      </td>
                                      <td
                                        style={{
                                          display: 'tableCell',
                                          verticalAlign: 'middle',
                                          textAlign: 'end',
                                        }}
                                        className='fs-5 text-dark fw-bolder'
                                      >
                                        $
                                        {a?.eventService.sellingPrice &&
                                          a.eventService.sellingPrice}
                                      </td>
                                    </tr>
                                    <td colSpan={15}>
                                      <div className='fw-semibold fs-7 text-gray-600 mb-3 mt-3'>
                                        <span className='fw-bold fs-7 text-gray-600 mb-3 mt-3'>
                                          {' '}
                                          Açıklama :
                                        </span>{' '}
                                        {a?.eventService?.definition}
                                      </div>
                                      <div className='fw-semibold fs-7 text-gray-600 mb-10'>
                                        <span className='fw-bold fs-7 text-gray-600 mb-3 mt-3'>
                                          Teklif Tarihi :
                                        </span>
                                        {moment(a.bidDate).format('YYYY-MM-DD')}
                                        <span className='fw-bold fs-7 text-gray-600 mb-3 mt-3'>
                                          - Geçerlilik Tarihi :{' '}
                                        </span>
                                        {moment(a.validDate).format('YYYY-MM-DD')}
                                        <span className='fw-bold fs-7 text-gray-600 mb-3 mt-3'>
                                          - Ödeme Tarihi :
                                        </span>{' '}
                                        {moment(a.paymentDate).format('YYYY-MM-DD')}
                                      </div>
                                    </td>
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )
                  })}
                  {/* ..... */}
                </div>
              </div>
            </div>

            <div className='m-0'>
              <div className='d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten'>
                <div className='d-flex justify-content-end' style={{flexDirection: 'column'}}>
                  <h6 className='mb-8 fw-bolder text-gray-600 text-hover-primary'>Teklif Tutarı</h6>
                  <div className='mw-300px'>
                    <div className='d-flex flex-stack mb-3'>
                      <div className='fw-semibold pe-10 text-gray-600 fs-7'>Subtotal:</div>

                      <div className='text-end fw-bold fs-6 text-gray-800'>$ 0.00</div>
                    </div>

                    <div className='d-flex flex-stack mb-3'>
                      <div className='fw-semibold pe-10 text-gray-600 fs-7'>VAT 0%</div>

                      <div className='text-end fw-bold fs-6 text-gray-800'>0.00</div>
                    </div>

                    <div className='d-flex flex-stack mb-3'>
                      <div className='fw-semibold pe-10 text-gray-600 fs-7'>Subtotal + VAT</div>

                      <div className='text-end fw-bold fs-6 text-gray-800'>$ 0.00</div>
                    </div>

                    <div className='d-flex flex-stack'>
                      <div className='fw-semibold pe-10 text-gray-600 fs-7'>Total</div>

                      <div className='text-end fw-bold fs-6 text-gray-800'>$ 00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card col-10 mt-7 '>
        <div className='card-body p-lg-20 d-flex flex-column gap-5 justify-content-center align-items-center'>
          {termData[0]?.termIsConfirmed ? (
            <label className=' fw-bold  fs-3 mb-2 text-success'>
              {intl.formatMessage({id: 'Bu etkinlik için bir teklif onayladı.'})}
            </label>
          ) : isAnyFeedback ? (
            <label className=' fw-bold  fs-3 mb-2 text-success'>
              {intl.formatMessage({id: 'Bu teklif için daha önce geri bildirim gönderildi.'})}
            </label>
          ) : (
            <>
              {' '}
              <label className=' fw-bold fs-3 mb-2'>
                {intl.formatMessage({id: 'Geri Bildirim'})}
              </label>
              <textarea
                rows={10}
                name='code'
                value={message}
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                onChange={(e: any) => setMessage(e.target.value)}
              />
              <button
                onClick={(e: any) => sendMessage()}
                className='btn btn-primary w-50'
                data-kt-items-modal-action='submit'
              >
                <span className='indicator-label'> {intl.formatMessage({id: 'Gönder'})}</span>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export {OfferDetail}
