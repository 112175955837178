import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'

export function MenuInner(menus: Array<string>) {
  const intl = useIntl()
  const uniqueTags: string[] = []
  Object.keys(menus).map((fullstr: any, key) => {
    if (
      uniqueTags.indexOf(menus[fullstr].split('.')[0].split('_')[1]) === -1 &&
      menus[fullstr].split('.')[0].split('_')[0] != 'main'
    ) {
      uniqueTags.push(menus[fullstr].split('.')[0].split('_')[1])
    }
  })

  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />

      {Object.keys(menus).map((fullstr: any, key) => {
        return (
          menus[fullstr].split('.')[0].split('_')[0] == 'main' && (
            <MenuItem
              key={key}
              to={'/' + menus[fullstr].split('.')[1].split('_')[0]}
              title={intl.formatMessage({id: 'MENU.' + menus[fullstr].split('.')[1].split('_')[1]})}
            />
          )
        )
      })}
      {uniqueTags.map((tag: any, key) => (
        <MenuInnerWithSub
          key={key}
          to='/library'
          title={intl.formatMessage({id: 'MENU.' + tag})}
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {Object.keys(menus).map(
            (fullstr: any, key) =>
              menus[fullstr].split('.')[0].split('_')[1] == tag && (
                <MenuItem
                  key={key}
                  to={'/' + menus[fullstr].split('.')[1].split('_')[0]}
                  title={intl.formatMessage({
                    id: 'MENU.' + menus[fullstr].split('.')[1].split('_')[1],
                  })}
                />
              )
          )}
        </MenuInnerWithSub>
      ))}

      {/* <MenuItem title='Layout Builder' to='/builder' /> 
      
      
      <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
        
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem
          icon='/media/icons/duotune/general/gen051.svg'
          to='/apps/user-management/users'
          title='User management'
        />
      </MenuInnerWithSub>
      */}
    </>
  )
}
