import {KTSVG} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import React, {FC, useState, useEffect} from 'react'
import {listOfferbyValidDate} from '../offer/list/core/_requests'
import moment from 'moment'
type Props = {
  className: string
}

const UpcomingOffers: React.FC<Props> = ({className}) => {
  const [offers, setOffers] = React.useState([])

  useEffect(() => {
    listOfferbyValidDate().then((res: any) => {
      setOffers(res.data || [])
    })
  }, [])

  //   <i className='fa fa-genderless text-warning fs-1'></i>
  //   <i className='fa fa-genderless text-success fs-1'></i>
  //   <i className='fa fa-genderless text-danger fs-1'></i>
  //   <i className='fa fa-genderless text-primary fs-1'></i>
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>
            Yaklaşan Teklifler (etkinliğine ait onaylı teklif olmayanlar)
          </span>
          <span className='text-muted fw-semibold fs-7'></span>
        </h3>
      </div>

      <div
        className='card-body pt-5'
        style={{height: '350px', paddingBottom: '50px', overflowY: 'scroll'}}
      >
        <div className='timeline-label'>
          {offers &&
            offers?.map((item: any, index: number) => {
              const colors = ['text-warning', 'text-success', 'text-danger', 'text-primary']
              const colorIndex = index % colors.length // Renk indeksi hesaplanır
              return (
                <div className='timeline-item'>
                  {/* begin::Label */}
                  <div className='timeline-label fw-bold text-gray-800 fs-6'>
                    {item.validDate && moment(item.validDate).format('YYYY-MM-DD')}
                  </div>
                  {/* end::Label */}
                  {/* begin::Badge */}
                  <div className='timeline-badge'>
                    <i className={`fa fa-genderless ${colors[colorIndex]} fs-1`}></i>
                  </div>
                  {/* end::Badge */}
                  {/* begin::Content */}
                  <div className='timeline-content d-flex ' style={{flexDirection: 'row'}}>
                    <p className='fw-bold text-gray-800 ps-3'>
                      {item.offerName && item.offerName}{' '}
                    </p>
                    <p className='text-muted fw-semibold ps-3 '>
                      Etkinlik : {item.termName && item.termName}
                    </p>
                  </div>
                  {/* end::Content */}
                </div>
              )
            })}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {UpcomingOffers}
