import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget10,
  MixedWidget8,
  TilesWidget1,
  TilesWidget4,
  TilesWidget2,
  TilesWidget5,
  TilesWidget3,
} from '../../../_metronic/partials/widgets'
import {UpcomingTerms} from './upcomingTerms'
import {UpcomingOffers} from './upcomingOffer'
import {Links} from './links'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import {UpcomingEventService} from './upcomingEventService'

const DashboardPage = () => (
  <>
    <div className='row g-5 g-xl-12'>
      <div className='card col-xl-12 p-3 mb-5'>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView='dayGridMonth'
          weekends={true}
          locale='tr'
          timeZone='UTC'
          firstDay={1}
          headerToolbar={{
            start: 'title', // will normally be on the left. if RTL, will be on the right
            end: 'prev,next', // will normally be on the right. if RTL, will be on the left
          }}
          editable={true}
          eventHint={'dfs'}
          displayEventTime={true}
          eventClick={function (arg) {
            alert(arg.event.title)
            alert(arg.event.start)
          }}
          events={[
            {
              title: 'Ramazan Bayramı',
              start: '2023-04-20',
              end: '2023-04-23',
              display: 'background',
            },

            {title: 'Otelin Son Ödeme Günü', date: '2023-03-03', overlap: true},
            {title: 'Onur Bey', start: '2023-03-02', display: 'background'},
            {title: 'Hematoloji Kongresi', start: '2023-04-15', end: '2023-04-19', color: 'purple'},
            {groupId: '999', title: 'Asena Doğum Günü', start: '2023-03-16T10:00:00+00:00'},
            {groupId: '999', title: 'Proje Sunum', start: '2023-03-16T10:30:00+00:00'},
            {groupId: '999', title: 'Öğle Yemeği', start: '2023-03-16T12:30:00+00:00'},
            {groupId: '999', title: 'Genel Değerlendirme', start: '2023-03-16T14:00:00+00:00'},
            {groupId: '999', title: 'Ozan Bey İsyan', start: '2023-03-16T16:30:00+00:00'},
            {groupId: '999', title: 'Kapanış', start: '2023-03-16T17:00:00+00:00'},
            {title: 'Gala Günü', start: '2023-03-18', end: '2023-03-20'},
            {
              title: 'Ekip Toplantı',
              start: '2023-03-19T10:30:00+00:00',
              end: '2023-03-19T12:30:00+00:00',
            },
            {title: 'Yemek', start: '2023-04-19T12:00:00+00:00'},
            {title: 'Hilton Opsiyon Sonu', start: '2023-03-20T07:00:00+00:00'},
            {title: 'Red And More Zirvesi', start: '2023-03-28'},

            {
              title: 'Dermatoloji Kongresi',
              date: '2023-03-01',
              color: '#ffcc00',
              allDay: true,
              editable: false,
            },
            {title: 'Ozan Beyin Doğum Günüsü', date: '2023-03-04', start: '10.30'},
            {
              title: 'Ozan Beyin Evlenme Yıldönümü',
              date: '2023-03-17',
              color: '#ffff00',
              backgroundColor: '#cc0000',
              font: 'Helvetica',
            },
            {title: 'CODE35 Toplantı', date: '2023-03-21'},
            {
              title: 'Ali Bey AÖF Sınavı',
              date: '2023-04-01',
              color: '#000',
              backgroundColor: '#aeaeae',
              interactive: true,
            },
          ]}
        />
      </div>
    </div>

    <div className='row gy-5 g-xl-8'>
      {/* <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div> */}
      <div className='col-xxl-6 '>
        <UpcomingTerms className='card-xxl-stretch ' />
      </div>
      <div className='col-xl-6'>
        <Links className='card-xl-stretch mb-xl-8' />
      </div>

      {/* <div className='col-xxl-6'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div> */}
    </div>

    <div className='row gy-5 gx-xl-8'>
      {/* <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div> */}
      <div className='col-xxl-6'>
        <UpcomingOffers className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-6'>
        <UpcomingEventService className='card-xxl-stretch' />
      </div>
    </div>

    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div>
    </div>
  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
