/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import React, {FC, useState, useEffect} from 'react'
import {createLink as create} from '../link/list/core/_requests'
import {useQueryResponse} from '../link/list/core/QueryResponseProvider'

type Props = {
  setTrigger: any
}

const Dropdown1: React.FC<Props> = ({setTrigger}) => {
  const {mode} = useThemeMode()
  const [name, setName] = React.useState<any>('')
  const [webLink, setLinks] = React.useState<any>('')
  const {refetch} = useQueryResponse()

  const createLink = () => {
    create({name: name, webLink: webLink}).then((res: any) => {
      refetch()
      setLinks('')
      setName('')
      setTrigger(Math.floor(Math.random() * 10000) + 1)
    })
  }
  return (
    <div className='menu menu-sub menu-sub-dropdown w-350px w-md-350px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bold'>Link Ekle</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-semibold'>Başlık</label>

          <div>
            <input
              type='text'
              name='name'
              value={name}
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-label fw-semibold'>URL</label>

          <div className='d-flex'>
            <input
              type='text'
              name='url'
              value={webLink}
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              onChange={(e: any) => setLinks(e.target.value)}
            />
          </div>
        </div>

        <div className='d-flex justify-content-end gap-5'>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
            // data-kt-menu-dismiss='true'
            onClick={(e) => {
              setLinks('')
              setName('')
            }}
          >
            Temizle
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
            // data-kt-menu-dismiss='true'
            disabled={name == '' || webLink == ''}
            onClick={(e: any) => createLink()}
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  )
}
export {Dropdown1}
