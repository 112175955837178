import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Model, QueryResponse} from './_models'
import qs from 'qs'

const API_URL = process.env.REACT_APP_API_URL
const PROP_URL = `${API_URL}/Term`
const PROP_URL2 = `${API_URL}/Custom/getTermReminder`

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const getTerm = (
  query: string,
  select?: string,
  modelstoinclude?: string
): Promise<QueryResponse> => {
  const qis = query.split('&')
  const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).eventId
  let newq: Array<string> = []
  qis.map((q) => {
    if (q.startsWith('filter') && q.slice(-1) != '=') {
      if (q.includes('_id')) {
        const aq = capitalizeFirstLetter(
          q.replace('_id', 'Id').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('strt') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('strt', '').replace('filter_', '').replaceAll('-', '.').replace('=', '>')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('ndnd') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('ndnd', '').replace('filter_', '').replaceAll('-', '.').replace('=', '<')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('string')) {
        const aq = capitalizeFirstLetter(
          q.replace('string', '').replace('filter_', '').replace('=', '_')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('stNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('stNMB', '').replace('filter_', '').replace('=', '>')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('enNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('enNMB', '').replace('filter_', '').replace('=', '<')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('BL')) {
        const aq = capitalizeFirstLetter(
          q.replace('BL', '').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      }
    }
  })
  const newqstring = newq.filter((f) => f).join('|')

  let mti = ''
  if (modelstoinclude != undefined && modelstoinclude != '') {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  let sti = ''
  if (select != undefined && select != '') {
    sti = '&select=' + select
  }

  return axios
    .get(`${PROP_URL}/getAll/${newqstring}?${query}${mti}${sti}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const listTerm = async (select?: string, modelstoinclude?: string): Promise<any> => {
  let mti = ''
  if (modelstoinclude != undefined) {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  return await axios
    .get(`${PROP_URL}/getAll?page=1&items_per_page=99999${mti}&select=${select}`)
    .then((res: AxiosResponse) => {
      return res.data
    })
}
const listTermbyLeadId = async (id: any): Promise<any> => {
  return await axios
    .get(`${PROP_URL}/getAll/ApplicationUserId*${id}?page=1&items_per_page=99999`)
    .then((res: AxiosResponse) => {
      return res.data
    })
}

const listTermbyValidDate = async (): Promise<any> => {
  return await axios.get(`${PROP_URL2}`).then((res: AxiosResponse) => {
    return res.data
  })
}

const getTermById = (id: any): Promise<Model | undefined> => {
  return axios
    .get(`${PROP_URL}/${id}`)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response as any)
}

const createTerm = (prop: any): Promise<Model | undefined> => {
  const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).eventId ?? ''
  prop.eventId = parseInt(qsd?.toString())
  return axios
    .put(PROP_URL, prop)
    .then((response: any) => response)
    .then((response: any) => response)
}

const updateTerm = (prop: Model): Promise<Model | undefined> => {
  return axios
    .post(`${PROP_URL}/${prop.id}`, prop)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
}

const deleteTerm = (propId: ID): Promise<void> => {
  return axios.delete(`${PROP_URL}/${propId}`).then(() => {})
}

const deleteSelectedTerm = (propIds: Array<ID>): Promise<void> => {
  const requests = propIds.map((id) => axios.delete(`${PROP_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getTerm,
  deleteTerm,
  deleteSelectedTerm,
  getTermById,
  createTerm,
  updateTerm,
  listTerm,
  listTermbyLeadId,
  listTermbyValidDate,
}
