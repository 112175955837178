/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {getTodoUser} from '../../../../app/pages/todouser/list/core/_requests'
import {useAuth} from '../../../../app/modules/auth'
// import {getall} from '../../../../app/pages/todouser/list/core/_requests'
import moment from 'moment'
import {Context} from './context'
import {ActionsCell} from './ActionsCell'
import {Model} from '../../../../app/pages/todouser/list/core/_models'

type Props = {
  trick: number
  settrick: Function
  tasks: Array<Model>
  setTasks: Function
}

const Item1: FC<Props> = ({trick, settrick, tasks, setTasks}) => {
  const {currentUser} = useAuth()

  useEffect(() => {
    // getall().then((res: any) => {
    //   setTasks(res || [])
    // })

    getTodoUser(
      'page=1&items_per_page=99999&filter_ApplicationUser_id*' + currentUser?.id,
      'Id,ApplicationUser,IsOnlyViewer,TodoList',
      'ApplicationUser,TodoList'
    ).then((res) => {
      setTasks(res.data?.filter((a) => a.todoList?.isComplete == false) || [])
    })
  }, [trick])

  function handleChildClick(msg: any) {
    settrick(msg)
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-40px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
        <div className='symbol-label bg-light'>
          <KTSVG
            path='/media/icons/duotune/communication/com003.svg'
            className='svg-icon-2 svg-icon-gray-500'
          />
        </div>
      </div>

      <div className='timeline-content mb-10 mt-n1'>
        <div className='pe-3 mb-5'>
          {/* <div className='fs-5 fw-semibold mb-2'>
            There are 2 new tasks for you in “AirPlus Mobile APp” project:
          </div> */}

          <div className='d-flex align-items-center mt-1 fs-6'>
            {/* <div className='text-muted me-2 fs-7'>Added at 4:23 PM by</div>

            <div
              className='symbol symbol-circle symbol-25px'
              data-bs-toggle='tooltip'
              data-bs-boundary='window'
              data-bs-placement='top'
              title='Nina Nilson'
            >
              <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='img' />
            </div> */}
          </div>
        </div>

        <div className='overflow-auto pb-5'>
          <>
            {tasks
              .filter((a) => a.todoList?.isNote)
              .map((a: any, key: any) => {
                return (
                  <div
                    key={key}
                    className='d-flex align-items-center border border-dashed border-gray-300 rounded  px-4 py-3 mb-5'
                    style={{width: '480px'}}
                  >
                    <a href='#' className='fs-5 text-dark  fw-semibold  min-w-150px'>
                      {a.todoList.name}
                    </a>

                    {/* <div className='min-w-375px pe-2'>
                    <a
                      className='badge badge-light text-muted'
                      onClick={() => setShowCreateAppModal(true)}
                    >
                      {a.todoList.content.substring(0, 25)}
                    </a>
                    <CreateAppModal
                      item={a.todoList}
                      show={showCreateAppModal}
                      handleClose={() => setShowCreateAppModal(false)}
                    />
                  </div> */}

                    <Context item={a.todoList} />

                    {/* <div className='min-w-125px pe-2'>
                    <span className='badge badge-light-primary'>In Progress</span>
                  </div> */}
                    <div className=' pe-2 min-w-90px'>
                      <span className=''>
                        {a.todoList.theDate ? moment(a.todoList.theDate).format('YYYY-MM-DD') : '-'}
                      </span>
                    </div>
                    <ActionsCell item={a.todoList} handleClick={handleChildClick} />
                  </div>
                )
              })}
          </>
        </div>
      </div>
    </div>
  )
}

export {Item1}
