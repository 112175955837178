/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {Model} from '../../../../app/pages/todolist/list/core/_models'
// import {defaultCreateAppData, ICreateAppData} from './IAppModels'
// import {StepperComponent} from '../../../assets/ts/components'
// import {KTSVG} from '../../../helpers'
// import {Step1} from './steps/Step1'
// import {Step2} from './steps/Step2'
// import {Step3} from './steps/Step3'
// import {Step4} from './steps/Step4'
// import {Step5} from './steps/Step5'
import {KTSVG} from '../../../helpers'
import {StepperComponent} from '../../../assets/ts/components'
import {defaultCreateAppData, ICreateAppData} from '../../modals/create-app-stepper/IAppModels'
import {CreateAppModal} from './messageModal'

type Props = {
  item: Model
}

const modalsRoot = document.getElementById('root-modals') || document.body

const Context = ({item}: Props) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)

  return (
    <div className='min-w-150px pe-2'>
      <a
        className='badge badge-light text-muted text-hover-primary '
        style={{cursor: 'pointer'}}
        onClick={() => setShowCreateAppModal(true)}
      >
        {item?.content?.substring(0, 15)}
      </a>
      <CreateAppModal
        item={item}
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
      />
    </div>
  )
}

export {Context}
