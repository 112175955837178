import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = (menus: Array<string>) => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../pages/applicationuser/Page'))
  const EventCategoryPage = lazy(() => import('../pages/eventcategory/Page'))
  const EventTypePage = lazy(() => import('../pages/eventtype/Page'))
  const SponsorTypePage = lazy(() => import('../pages/sponsortype/Page'))
  const ServiceSpesificationPage = lazy(() => import('../pages/servicespesification/Page'))
  const ServicePage = lazy(() => import('../pages/service/Page'))
  const UnitPage = lazy(() => import('../pages/unit/Page'))
  const AbstractCategoryPage = lazy(() => import('../pages/abstractcategory/Page'))
  const UserContactPage = lazy(() => import('../pages/usercontact/Page'))
  const MeetingTypePage = lazy(() => import('../pages/meetingtype/Page'))
  const EventTopicPage = lazy(() => import('../pages/event-topic/Page'))
  const MeetingPage = lazy(() => import('../pages/meeting/Page'))
  const EventPage = lazy(() => import('../pages/event/Page'))
  const TermPage = lazy(() => import('../pages/term/Page'))
  const EventTeamPage = lazy(() => import('../pages/eventteam/Page'))
  const ParticipantPage = lazy(() => import('../pages/participant/Page'))
  const TodoPage = lazy(() => import('../pages/todolist/Page'))
  const DayPage = lazy(() => import('../pages/day/Page'))
  const SessionPage = lazy(() => import('../pages/session/Page'))
  const EventServicePage = lazy(() => import('../pages/eventservice/Page'))
  const ServicePricePage = lazy(() => import('../pages/serviceprice/Page'))
  // const PriceUnitPage = lazy(() => import('../pages/priceunit/Page'))
  const SponsorshipPage = lazy(() => import('../pages/sponsorship/Page'))
  const EventSponsorPage = lazy(() => import('../pages/eventsponsor/Page'))
  const StandPlanPage = lazy(() => import('../pages/standplan/Page'))
  const AbstractPage = lazy(() => import('../pages/abstract/Page'))
  const PromotionPage = lazy(() => import('../pages/promotion/Page'))
  const PromotionStockPage = lazy(() => import('../pages/promotionstock/Page'))
  const LinkPage = lazy(() => import('../pages/link/Page'))
  const DocumentTypePage = lazy(() => import('../pages/documenttype/Page'))
  const DocumentPage = lazy(() => import('../pages/document/Page'))
  const TransactionPage = lazy(() => import('../pages/transaction/Page'))
  const DetailCategoryPage = lazy(() => import('../pages/detailcategory/Page'))
  const TransactionDetailPage = lazy(() => import('../pages/transactiondetail/Page'))
  const AnnouncementPage = lazy(() => import('../pages/announcement/Page'))
  const CountryPage = lazy(() => import('../pages/country/Page'))
  const CityPage = lazy(() => import('../pages/city/Page'))
  const ApplicationUserPage = lazy(() => import('../pages/applicationuser/Page'))
  const ApplicationRolePage = lazy(() => import('../pages/applicationrole/Page'))
  const ApplicationUserRolePage = lazy(() => import('../pages/applicationuserrole/Page'))
  const ApplicationUserClaimPage = lazy(() => import('../pages/applicationuserclaim/Page'))
  const TenantPage = lazy(() => import('../pages/tenant/Page'))
  const AllEvent = lazy(() => import('../pages/all-event/Page'))
  const ApplicationUser = lazy(() => import('../pages/applicationuser/Page'))
  const ActiveEvents = lazy(() => import('../pages/active-event/Page'))
  const ApprovedEventService = lazy(() => import('../pages/approved-eventservice/Page'))
  // const ApprovedTeamTask = lazy(() => import('../pages/approved-teamtask/Page'))
  const MenuList = lazy(() => import('../pages/menulist/Page'))
  const MenuRole = lazy(() => import('../pages/menurole/Page'))
  const TodoUser = lazy(() => import('../pages/todouser/Page'))
  const Offer = lazy(() => import('../pages/offer/Page'))
  const OfferItem = lazy(() => import('../pages/offeritem/Page'))
  const Profile = lazy(() => import('../pages/profile/Page'))
  const ActiveTodoList = lazy(() => import('../pages/active-event-todoList/Page'))
  const MeetingMessagePage = lazy(() => import('../pages/meetingmessage/Page'))
  const PaymentPage = lazy(() => import('../pages/payment/Page'))
  const AccreditationPage = lazy(() => import('../pages/acraditeDays/Page'))
  const Accreditation = lazy(() => import('../pages/accreditation/Page'))

  return (
    <Routes>
      <Route element={<MasterLayout {...(menus || [])} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='offer/*'
          element={
            <SuspensedView>
              <Offer />
            </SuspensedView>
          }
        />
        <Route
          path='payment/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />

        <Route
          path='accreditationDays/*'
          element={
            <SuspensedView>
              <AccreditationPage />
            </SuspensedView>
          }
        />
        <Route
          path='accreditation/*'
          element={
            <SuspensedView>
              <Accreditation />
            </SuspensedView>
          }
        />

        <Route
          path='active-todo-list/*'
          element={
            <SuspensedView>
              <ActiveTodoList />
            </SuspensedView>
          }
        />
        <Route
          path='meetingmessage/*'
          element={
            <SuspensedView>
              <MeetingMessagePage />
            </SuspensedView>
          }
        />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <Profile />
            </SuspensedView>
          }
        />
        <Route
          path='offeritem/*'
          element={
            <SuspensedView>
              <OfferItem />
            </SuspensedView>
          }
        />
        <Route
          path='event-category/*'
          element={
            <SuspensedView>
              <EventCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='menulist/*'
          element={
            <SuspensedView>
              <MenuList />
            </SuspensedView>
          }
        />
        <Route
          path='todouser/*'
          element={
            <SuspensedView>
              <TodoUser />
            </SuspensedView>
          }
        />
        <Route
          path='menurole/*'
          element={
            <SuspensedView>
              <MenuRole />
            </SuspensedView>
          }
        />
        {/* <Route
          path='approved-team-task/*'
          element={
            <SuspensedView>
              <ApprovedTeamTask />
            </SuspensedView>
          }
        /> */}
        <Route
          path='approved-eventservice/*'
          element={
            <SuspensedView>
              <ApprovedEventService />
            </SuspensedView>
          }
        />
        <Route
          path='all-event/*'
          element={
            <SuspensedView>
              <AllEvent />
            </SuspensedView>
          }
        />
        <Route
          path='eventtype/*'
          element={
            <SuspensedView>
              <EventTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='sponsortype/*'
          element={
            <SuspensedView>
              <SponsorTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='servicespesification/*'
          element={
            <SuspensedView>
              <ServiceSpesificationPage />
            </SuspensedView>
          }
        />
        <Route
          path='service/*'
          element={
            <SuspensedView>
              <ServicePage />
            </SuspensedView>
          }
        />
        <Route
          path='unit/*'
          element={
            <SuspensedView>
              <UnitPage />
            </SuspensedView>
          }
        />
        <Route
          path='abstract-category/*'
          element={
            <SuspensedView>
              <AbstractCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='usercontact/*'
          element={
            <SuspensedView>
              <UserContactPage />
            </SuspensedView>
          }
        />
        <Route
          path='meetingtype/*'
          element={
            <SuspensedView>
              <MeetingTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='eventtopic/*'
          element={
            <SuspensedView>
              <EventTopicPage />
            </SuspensedView>
          }
        />
        <Route
          path='meeting/*'
          element={
            <SuspensedView>
              <MeetingPage />
            </SuspensedView>
          }
        />
        <Route
          path='event/*'
          element={
            <SuspensedView>
              <ActiveEvents />
            </SuspensedView>
          }
        />
        <Route
          path='term/*'
          element={
            <SuspensedView>
              <TermPage />
            </SuspensedView>
          }
        />
        <Route
          path='eventteam/*'
          element={
            <SuspensedView>
              <EventTeamPage />
            </SuspensedView>
          }
        />
        <Route
          path='participant/*'
          element={
            <SuspensedView>
              <ParticipantPage />
            </SuspensedView>
          }
        />
        <Route
          path='todo-list/*'
          element={
            <SuspensedView>
              <TodoPage />
            </SuspensedView>
          }
        />
        <Route
          path='day/*'
          element={
            <SuspensedView>
              <DayPage />
            </SuspensedView>
          }
        />
        <Route
          path='session/*'
          element={
            <SuspensedView>
              <SessionPage />
            </SuspensedView>
          }
        />
        <Route
          path='eventservice/*'
          element={
            <SuspensedView>
              <EventServicePage />
            </SuspensedView>
          }
        />
        <Route
          path='serviceprice/*'
          element={
            <SuspensedView>
              <ServicePricePage />
            </SuspensedView>
          }
        />
        <Route
          path='sponsorship/*'
          element={
            <SuspensedView>
              <SponsorshipPage />
            </SuspensedView>
          }
        />
        <Route
          path='eventsponsor/*'
          element={
            <SuspensedView>
              <EventSponsorPage />
            </SuspensedView>
          }
        />
        <Route
          path='standplan/*'
          element={
            <SuspensedView>
              <StandPlanPage />
            </SuspensedView>
          }
        />
        <Route
          path='abstract/*'
          element={
            <SuspensedView>
              <AbstractPage />
            </SuspensedView>
          }
        />
        <Route
          path='promotion/*'
          element={
            <SuspensedView>
              <PromotionPage />
            </SuspensedView>
          }
        />
        <Route
          path='promotionstock/*'
          element={
            <SuspensedView>
              <PromotionStockPage />
            </SuspensedView>
          }
        />
        <Route
          path='link/*'
          element={
            <SuspensedView>
              <LinkPage />
            </SuspensedView>
          }
        />
        <Route
          path='document-type/*'
          element={
            <SuspensedView>
              <DocumentTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='document/*'
          element={
            <SuspensedView>
              <DocumentPage />
            </SuspensedView>
          }
        />
        <Route
          path='transaction/*'
          element={
            <SuspensedView>
              <TransactionPage />
            </SuspensedView>
          }
        />
        <Route
          path='detailcategory/*'
          element={
            <SuspensedView>
              <DetailCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='transactiondetail/*'
          element={
            <SuspensedView>
              <TransactionDetailPage />
            </SuspensedView>
          }
        />
        <Route
          path='announcement/*'
          element={
            <SuspensedView>
              <AnnouncementPage />
            </SuspensedView>
          }
        />
        <Route
          path='country/*'
          element={
            <SuspensedView>
              <CountryPage />
            </SuspensedView>
          }
        />
        <Route
          path='city/*'
          element={
            <SuspensedView>
              <CityPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuser/*'
          element={
            <SuspensedView>
              <ApplicationUserPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationrole/*'
          element={
            <SuspensedView>
              <ApplicationRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuserrole/*'
          element={
            <SuspensedView>
              <ApplicationUserRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuserclaim/*'
          element={
            <SuspensedView>
              <ApplicationUserClaimPage />
            </SuspensedView>
          }
        />
        <Route
          path='tenant/*'
          element={
            <SuspensedView>
              <TenantPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
