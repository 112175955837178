import {KTSVG} from '../../../_metronic/helpers'
// import {Dropdown1} from '../../../_metronic/partials'
import {Dropdown1} from './linksDropdown'
import {listLink} from '../link/list/core/_requests'
import React, {FC, useState, useEffect} from 'react'
import {deleteLink} from '../link/list/core/_requests'
import {useQueryResponse} from '../link/list/core/QueryResponseProvider'

type Props = {
  className: string
}

const Links: React.FC<Props> = ({className}) => {
  const [links, setLinks] = React.useState([])
  const [trigger, setTrigger] = React.useState(0)
  const {refetch} = useQueryResponse()
  console.log(trigger)
  useEffect(() => {
    listLink('id,Name,WebLink').then((res: any) => {
      setLinks(res.data || [])
    })
  }, [trigger])

  const deleteUrl = (id: any) => {
    console.log(id)
    deleteLink(id).then((res: any) => {
      refetch()
      setTrigger(Math.floor(Math.random() * 10000) + 1)
    })
  }

  console.log(links)
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Faydalı Linkler</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 setTrigger={setTrigger} />
          {/* end::Menu */}
        </div>
      </div>

      <div className='card-body pt-0' style={{height: '400px', overflowY: 'scroll'}}>
        {links &&
          links.map((item: any, index: number) => {
            const colors = [
              'bg-light-warning',
              'bg-light-success',
              'bg-light-danger',
              'bg-light-primary',
            ]
            const colors2 = ['text-warning', 'text-success', 'text-danger', 'text-primary']
            const colorIndex = index % colors.length // Renk indeksi hesaplanır
            const colorIndex2 = index % colors2.length // Renk indeksi hesaplanır
            return (
              <div className={`d-flex align-items-center ${colors[colorIndex]} rounded p-5 mb-7`}>
                {/* begin::Icon */}
                <span className='svg-icon svg-icon-warning me-5'>
                  <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                </span>
                {/* end::Icon */}
                {/* begin::Title */}
                <div className='flex-grow-1 me-2'>
                  <a
                    href={`${item.webLink}`}
                    className='fw-bold text-gray-800 text-hover-primary fs-6'
                  >
                    {item.name && item.name}
                  </a>
                  {/* <span className='text-muted fw-semibold d-block'>Due in 2 Days</span> */}
                </div>
                {/* end::Title */}
                {/* begin::Lable */}
                <span
                  className={`fw-bold ${colors2[colorIndex]} py-1`}
                  style={{cursor: 'pointer'}}
                  onClick={(e: any) => deleteUrl(item.id)}
                >
                  Sil
                </span>
                {/* end::Lable */}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export {Links}
