import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Model, QueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const PROP_URL = `${API_URL}/Offer`
const PROP_URL2 = `${API_URL}/Custom/getOfferReminder`
const PROP_URL3 = `${API_URL}/Custom/getPublicTermOffers`
const PROP_URL4 = `${API_URL}/Custom/getPublicTermOfferDetail`
const PROP_URL_NOTE = `${API_URL}/Custom/addOfferNote`
const OfferNote = `${API_URL}/Custom/getOfferNoteByOfferId`

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const getOffer = (
  query: string,
  select?: string,
  modelstoinclude?: string
): Promise<QueryResponse> => {
  const qis = query.split('&')
  let newq: Array<string> = []
  qis.map((q) => {
    if (q.startsWith('filter') && q.slice(-1) != '=') {
      if (q.includes('_id')) {
        const aq = capitalizeFirstLetter(
          q.replace('_id', 'Id').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('strt') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('strt', '').replace('filter_', '').replaceAll('-', '.').replace('=', '>')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('ndnd') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('ndnd', '').replace('filter_', '').replaceAll('-', '.').replace('=', '<')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('string')) {
        const aq = capitalizeFirstLetter(
          q.replace('string', '').replace('filter_', '').replace('=', '_')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('stNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('stNMB', '').replace('filter_', '').replace('=', '>')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('enNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('enNMB', '').replace('filter_', '').replace('=', '<')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('BL')) {
        const aq = capitalizeFirstLetter(
          q.replace('BL', '').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      }
    }
  })
  const newqstring = newq.filter((f) => f).join('|')

  let mti = ''
  if (modelstoinclude != undefined && modelstoinclude != '') {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  let sti = ''
  if (select != undefined && select != '') {
    sti = '&select=' + select
  }

  return axios
    .get(`${PROP_URL}/getAll/${newqstring}?${query}${mti}${sti}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const listOffer = async (select?: string, modelstoinclude?: string): Promise<any> => {
  let mti = ''
  if (modelstoinclude != undefined) {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  return await axios
    .get(`${PROP_URL}/getAll?page=1&items_per_page=99999${mti}&select=${select}`)
    .then((res: AxiosResponse) => {
      return res.data
    })
}

const listPublicOffers = async (termId: any): Promise<any> => {
  return await axios.get(`${PROP_URL3}?termid=${termId}`).then((res: AxiosResponse) => {
    return res.data
  })
}

const listPublicTermDetail = async (offerId: any): Promise<any> => {
  return await axios.get(`${PROP_URL4}?offerid=${offerId}`).then((res: AxiosResponse) => {
    return res.data
  })
}
const listOfferbyValidDate = async (): Promise<any> => {
  return await axios.get(`${PROP_URL2}`).then((res: AxiosResponse) => {
    return res.data
  })
}

const getOfferById = (id: any): Promise<Model | undefined> => {
  return axios
    .get(`${PROP_URL}/${id}`)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response as any)
}

const getOfferByOfferId = (OfferId: any): Promise<any | undefined> => {
  return axios
    .get(`${OfferNote}?offerid=${OfferId}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response as any)
}

const createOffer = (prop: any): Promise<Model | undefined> => {
  return axios
    .put(PROP_URL, prop)
    .then((response: any) => response)
    .then((response: any) => response)
}
const createOfferNote = (prop: any): Promise<any | undefined> => {
  return axios
    .put(PROP_URL_NOTE, prop)
    .then((response: any) => response)
    .then((response: any) => response)
}

const updateOffer = (prop: Model): Promise<Model | undefined> => {
  return axios
    .post(`${PROP_URL}/${prop.id}`, prop)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
}

const deleteOffer = (propId: ID): Promise<void> => {
  return axios.delete(`${PROP_URL}/${propId}`).then(() => {})
}

const deleteSelectedOffer = (propIds: Array<ID>): Promise<void> => {
  const requests = propIds.map((id) => axios.delete(`${PROP_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getOffer,
  deleteOffer,
  deleteSelectedOffer,
  getOfferById,
  createOffer,
  updateOffer,
  listOffer,
  listOfferbyValidDate,
  listPublicOffers,
  listPublicTermDetail,
  createOfferNote,
  getOfferByOfferId,
}
