import React, {FC, useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {isNotEmpty, toAbsoluteUrl} from '../../../helpers'
import {Model} from '../../../../app/pages/todolist/list/core/_models'
import clsx from 'clsx'
import {useListView} from '../../../../app/pages/todolist/list/core/ListViewProvider'
import {ListLoading} from '../../../../app/pages/todolist/list/components/loading/ListLoading'
import {createTeamTask, updateTeamTask} from '../../../../app/pages/todolist/list/core/_requests'
import {useQueryResponse} from '../../../../app/pages/todolist/list/core/QueryResponseProvider'
import {useLocation} from 'react-router-dom'
import moment from 'moment'
import {listApplicationUser} from '../../../../app/pages/applicationuser/list/core/_requests'
import {createTodoUser} from '../../../../app/pages/todouser/list/core/_requests'
import {listEventService} from '../../../../app/pages/eventservice/list/core/_requests'
import CustomSelect from '../../../../app/modules/custom-select/CustomSelect'
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  trick: number
  settrick: Function
}

const editchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
})

const CustomEditModalForm: FC<Props> = ({trick, settrick}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [taskcategorys, settaskcategorys] = React.useState([])
  const [applicationusers, setapplicationusers] = React.useState([])
  const [eventservices, seteventservices] = React.useState([])

  const [users, setUsers] = useState<Array<any>>([])
  const {currentUser} = useAuth()

  useEffect(() => {
    listApplicationUser('Id,Name').then((res: any) => {
      setapplicationusers(
        res.data.map((a: any) => {
          return {value: a?.id, label: a?.name}
        }) || []
      )
    })

    listEventService('Id').then((res: any) => {
      seteventservices(res.data || [])
    })
  }, [])
  // useEffect(() => {

  // })
  const [placeForEdit] = useState<Model>({
    content: undefined,
    theDate: undefined,
    isComplete: false,
    termId: undefined,
    name: undefined,
    isBlocked: false,
    isNote: true,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const formik = useFormik({
    initialValues: placeForEdit,
    validationSchema: editchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateTeamTask(values)
        } else {
          await createTeamTask(values).then((todoitem) => {
            const todouser = {
              applicationUserId: currentUser?.id,
              todoListId: todoitem?.data?.id,
            }

            createTodoUser(todouser).then(() => {
              formik.values.name = ''
              formik.values.content = ''
              formik.values.theDate = new Date()
            })
          })
        }
      } catch (ex) {
      } finally {
        setSubmitting(true)
        cancel(true)
      }
      trick = trick + 1
      settrick(trick)
    },
  })
  // console.log(users)
  function handleRoleChange(data: any) {
    setUsers(data)
  }

  return (
    <>
      <form
        id='kt_modal_add_item_form'
        className='form '
        style={{width: '480px'}}
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_item_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_item_header'
          data-kt-scroll-wrappers='#kt_modal_add_item_scroll'
          data-kt-scroll-offset='300px'
        ></div>

        <div className='card-body px-2 py-9'>
          <div className='fv-row mb-7'>
            <label className=' required fw-bold fs-6 mb-2'>
              {intl.formatMessage({id: 'Title1'})}
            </label>
            <input
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className=' fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'Note1'})}</label>

            <textarea
              style={{height: '180px'}}
              // placeholder='Task'
              {...formik.getFieldProps('content')}
              rows={3}
              name='content'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.content && formik.errors.content},
                {
                  'is-valid': formik.touched.content && !formik.errors.content,
                }
              )}
              autoComplete='off'
              // disabled={formik.isSubmitting || isThingLoading}
            />
            {formik.touched.content && formik.errors.content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.content}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='  fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'Date'})}</label>
            <input
              {...formik.getFieldProps('theDate')}
              type='datetime-local'
              name='theDate'
              value={moment(formik.values.theDate).format('YYYY-MM-DD HH:mm')}
              onChange={(e) => {
                formik.setFieldValue('theDate', new Date(e.target.value).toISOString())
              }}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.theDate && formik.errors.theDate},
                {
                  'is-valid': formik.touched.theDate && !formik.errors.theDate,
                }
              )}
              autoComplete='off'
            />
            {formik.touched.theDate && formik.errors.theDate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.theDate}</span>
                </div>
              </div>
            )}
          </div>
          {/* {!formik.values.id && (
            <div className='fv-row mb-7'>
              <label className='  fw-bold fs-6 mb-2'>
                {intl.formatMessage({id: 'TASK_OWNER'})}
              </label>
              <CustomSelect
                options={applicationusers}
                onChange={handleRoleChange}
                value={users}
                isSearchable={true}
                isMulti
              />
            </div>
          )} */}

          {/* 
          <div className='row mb-3'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              {intl.formatMessage({id: 'IsComplete'})}
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <div className='form-check form-check-solid form-switch fv-row'>
                <input
                  {...formik.getFieldProps('isComplete')}
                  checked={formik.values.isComplete}
                  onChange={(e) => formik.setFieldValue('isComplete', e.target.checked)}
                  value={formik.values.isComplete ? 'on' : 'off'}
                  className='form-check-input w-80px mt-2 border-secondary'
                  type='checkbox'
                  id='isComplete'
                />
                <label className='form-check-label mt-3 px-5'>
                  {' '}
                  <small className='text-danger'>
                    {intl.formatMessage({id: 'IsComplete'})}
                  </small>{' '}
                </label>
              </div>
            </div>
          </div>

          <div className='row mb-3'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              {intl.formatMessage({id: 'isBlocked'})}
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <div className='form-check form-check-solid form-switch fv-row'>
                <input
                  {...formik.getFieldProps('isBlocked')}
                  checked={formik.values.isBlocked}
                  onChange={(e) => formik.setFieldValue('isBlocked', e.target.checked)}
                  value={formik.values.isBlocked ? 'on' : 'off'}
                  className='form-check-input w-80px mt-2 border-secondary'
                  type='checkbox'
                  id='isBlocked'
                />
                <label className='form-check-label mt-3 px-5'>
                  {' '}
                  <small className='text-danger'>
                    {intl.formatMessage({id: 'isBlocked'})}
                  </small>{' '}
                </label>
              </div>
            </div>
          </div> */}
        </div>
        <div className='text-center pt-5 pb-5'>
          {/* <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-items-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            {intl.formatMessage({id: 'FORM.DISCARD'})}
          </button> */}

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-items-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'> {intl.formatMessage({id: 'MODALFORM.SAVE'})}</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <ListLoading />}
    </>
  )
}

export {CustomEditModalForm}
