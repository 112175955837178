/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../helpers'
import {useListView} from '../../../../app/pages/todolist/list/core/ListViewProvider'
import {useQueryResponse} from '../../../../app/pages/todolist/list/core/QueryResponseProvider'
import {
  deleteTeamTask,
  getTeamTaskById,
  updateTeamTask,
} from '../../../../app/pages/todolist/list/core/_requests'
import {Model} from '../../../../app/pages/todolist/list/core/_models'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import {getTodoUserByUserId} from '../../../../app/pages/todouser/list/core/_requests'
import {useAuth} from '../../../../app/modules/auth'
import {deleteTodoUser2} from '../../../../app/pages/todouser/list/core/_requests'
import {EditModal} from './editModal'

type Props = {
  item: Model
  handleClick: any
}

const ActionsCell = ({item, handleClick}: Props) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()

  const queryClient = useQueryClient()
  // const {refetch} = useQueryResponse()
  const {currentUser} = useAuth()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const [todousers, settodousers] = React.useState([])

  // useEffect(() => {
  //   getTodoUserByUserId(currentUser?.id).then((res: any) => {
  //     settodousers(res.data || [])
  //   })
  // }, [])

  const openEditModal = () => {
    setItemIdForUpdate(item.id)
  }

  const updateItem = async () => {
    await getTeamTaskById(item.id).then((res: any) => {
      res.isComplete = !res.isComplete

      updateTeamTask(res).then(() => {})
    })
    // console.log(item.isClosed)
    // item.isClosed = !item.isClosed
    // console.log(item.isClosed)
    // updateMeeting(item)
  }

  const deleteItem2 = useMutation(() => deleteItem(), {
    // 💡 response of the mutation is passed to onSuccess

    onSuccess: () => {
      // ✅ update detail view directly

      const query = 'page=1&items_per_page=10'

      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      handleClick(Math.floor(Math.random() * 1000))
    },
  })

  const deleteItem = async () => {
    await deleteTodoUser2(item?.id)
      .then(async (res: any) => {
        await deleteTeamTask(item?.id).then(() => {})
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
        <EditModal
          item={item}
          show={showCreateAppModal}
          handleClose={() => setShowCreateAppModal(false)}
          handleClick={handleClick}
        />

        <a
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
          data-kt-users-table-filter='delete_row'
          onClick={async () => await deleteItem2.mutateAsync()}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>
    </>
  )
}

export {ActionsCell}

{
  /* <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          className={`btn btn-icon    btn-sm me-1 ${
            item.isComplete ? 'btn-success' : 'btn-warning'
          }`}
          style={item.isComplete ? {backgroundColor: '#82d18f'} : {backgroundColor: '#ffd119'}}
          onClick={() => {
            updateItem()
          }}
        >
          <KTSVG path='/media/icons/duotune/general/gen012.svg' className='svg-icon-3 ' />
        </a>
        <a
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={openEditModal}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>

        <a
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          data-kt-users-table-filter='delete_row'
          onClick={async () => await deleteItem.mutateAsync()}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div> */
}
