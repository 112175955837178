import React, {FC, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import qs from 'qs'
import {listPublicOffers} from '../../pages/offer/list/core/_requests'
const Offers: FC = () => {
  const [data, setData] = useState<any>([])
  const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).termId

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).termId
    listPublicOffers(qsd).then((res: any) => {
      console.log(res)
      setData(res)
    })
  }, [])
  console.log(data)
  return (
    <div className='d-flex flex-column flex-root row justify-content-center align-items-center m-0'>
      <div className={`card col-5 m-0`}>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title fw-bold text-dark border fs-2 border-dark border-bottom-1 w-100 mt-5 '>
            Teklifler
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}

            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-2'>
          {data?.map((item: any) => {
            return (
              <div className='d-flex align-items-center mb-8'>
                {/* begin::Bullet */}
                <span className='bullet bullet-vertical h-40px bg-success'></span>
                {/* end::Bullet */}
                {/* begin::Checkbox */}

                {/* end::Checkbox */}
                {/* begin::Description */}
                <div className='flex-grow-1'>
                  <Link
                    to={`/customeroffers/detail?offerId=${item.id}`}
                    className='text-gray-800 text-hover-primary fw-bold m-4 fs-4'
                  >
                    {item?.name}
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export {Offers}
