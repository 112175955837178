/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import React, {FC, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {Model} from '../../../../app/pages/todolist/list/core/_models'
// import {defaultCreateAppData, ICreateAppData} from './IAppModels'
// import {StepperComponent} from '../../../assets/ts/components'
// import {KTSVG} from '../../../helpers'
// import {Step1} from './steps/Step1'
// import {Step2} from './steps/Step2'
// import {Step3} from './steps/Step3'
// import {Step4} from './steps/Step4'
// import {Step5} from './steps/Step5'
import {KTSVG} from '../../../helpers'
import {StepperComponent} from '../../../assets/ts/components'
import {defaultCreateAppData, ICreateAppData} from '../../modals/create-app-stepper/IAppModels'
import {getTeamTaskById, updateTeamTask} from '../../../../app/pages/todolist/list/core/_requests'
import {useIntl} from 'react-intl'

type Props = {
  item: Model
  show: boolean
  handleClose: () => void
  handleClick: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const EditModal = ({item, show, handleClick, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData)
  const [hasError, setHasError] = useState(false)
  const [content, setContent] = useState<any>('')
  const [name, setName] = useState<any>('')
  const [date, setDate] = useState<any>('')
  const intl = useIntl()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const checkAppBasic = (): boolean => {
    if (!data.appBasic.appName || !data.appBasic.appType) {
      return false
    }
    return true
  }

  const checkAppDataBase = (): boolean => {
    if (!data.appDatabase.databaseName || !data.appDatabase.databaseSolution) {
      return false
    }

    return true
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkAppBasic()) {
        setHasError(true)
        return
      }
    }

    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkAppDataBase()) {
        setHasError(true)
        return
      }
    }

    stepper.current.goNext()
  }

  const submit = () => {
    window.location.reload()
  }
  useEffect(() => {
    if (item) {
      console.log(item)
      //   setServiceName(item?.seviceName)
      setContent(item?.content)
      setName(item?.name)
      setDate(item?.theDate)
    }
  }, [])

  const update = () => {
    getTeamTaskById(item.id).then((res: any) => {
      console.log(res)

      let payload = {
        content: content,
        createdAt: res?.createdAt,
        creatorId: res?.creatorId,
        deletedAt: res?.deletedAt,
        deletedPK: res?.deletedPK,
        description: res?.description,
        eventService: res?.eventService,
        eventServiceId: res?.eventServiceId,
        id: res?.id,
        isBlocked: res?.isBlocked,
        isComplete: res?.isComplete,
        isNote: res?.isNote,
        name: name,
        tenantId: res?.tenantId,
        theDate: date,
        updatedAt: res?.updatedAt,
        updaterId: res?.updaterId,
        year: res?.year,
      }
      console.log(payload)
      updateTeamTask(payload).then(() => {
        // setTriger(randomNumberInRange(1, 1255))
        handleClick(Math.floor(Math.random() * 1000))
        handleClose()
      })
    })
  }

  console.log(item)
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
    >
      <div className='modal-header'>
        <h2>{intl.formatMessage({id: 'MODAL.EDIT'})}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10 '>
        <div className='fv-row mb-7'>
          <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'Title'})}</label>
          <input
            type='text'
            name='name'
            className='form-control form-control-solid mb-3 mb-lg-0'
            autoComplete='off'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='fv-row mb-7'>
          <label className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'Note'})}</label>
          <input
            type='text'
            name='name'
            className='form-control form-control-solid mb-3 mb-lg-0'
            autoComplete='off'
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>

        <div className='fv-row mb-7'>
          <label className='  fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'Date'})}</label>
          <input
            type='datetime-local'
            name='theDate'
            value={date}
            className='form-control form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setDate(e.target.value)}
            autoComplete='off'
          />
        </div>

        <div className='text-center pt-15'>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-items-modal-action='submit'
            // disabled={isPlaceLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            onClick={(e) => update()}
          >
            <span className='indicator-label'> {intl.formatMessage({id: 'MODALFORM.SAVE'})}</span>
          </button>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {EditModal}
